import React, { useState, useEffect } from "react"
import View from "./View"
import BillingCharges from "./customers/BillingCharges"
import { getUser } from "../utils/auth"
import { CustomerService } from '../utils/ApiService';
import { Link } from "gatsby"
import { RouteComponentProps } from '@reach/router';
import { Table, Row, Col, Card } from 'react-bootstrap'
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai'
import { FiArrowLeft } from 'react-icons/fi'

const Customer = ({ id }) => {
  const user = getUser();
  const [data, setData] = useState([]);
  const [donationBox, setDonationBox] = useState(false);
  const [swishLinks, setSwishLinks] = useState(false);
  const [subscriptions, setSubscriptions] = useState(false);
  const { displayName, email, emailVerified } = user;
  const accessToken = user.stsTokenManager.accessToken;

  useEffect(
    () => {
      getData();
    }, []
  )

  function getData() {
    CustomerService.getOne(id)
      .then(r => {
        setData(r);
        for (const [key, val] of Object.entries(r.properties.services)) {
          console.log(key, val)
          switch (key) {
            case "Donation Box":
              setDonationBox(val);
              break;
            case "Swish links":
              setSwishLinks(val);
              break;
            case "Subscriptions":
              setSubscriptions(val);
              break;

            default:
              break;
          }
        }
      })
      .catch(e => console.log(e));
  }

  return (
    <View title="Dashboard">
      <h2>
        <Link to="/app/dashboard">
          <a><FiArrowLeft /></a>
        </Link>
        &nbsp;
        {data.name}</h2>
      <div style={{ height: 40 }}></div>
      <Row>
        <Col sm={12} md={6}>
          <Card>
            <Card.Body>
              <h3>Basic details</h3>
              <Table responsive>
                <tbody>
                  <tr>
                    <td>Portal ID</td>
                    <td>{data.id}</td>
                    <td>Portal FS ID</td>
                    <td>{data.fs_id}</td>
                  </tr>
                  <tr>
                    <td>SMS sender ID</td>
                    <td>{data.sms_sender_id}</td>
                    <td>Swish number</td>
                    <td>{data.swish_number}</td>
                  </tr>
                  <tr>
                    <td>API Key</td>
                    <td colSpan={3}>{data.api_key}</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>

        </Col>
        <Col sm={12} md={6}>
          <Card>
            <Card.Body>
              <h3>Active Services</h3>
              <Table>
                <thead>
                  <tr>
                    <th>Donation Box</th>
                    <th>Swish links</th>
                    <th>Subscriptions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ fontSize: 25 }}>{donationBox ? (<AiOutlineCheckCircle style={{ color: 'green' }} />) : (<AiOutlineCloseCircle />)}</td>
                    <td style={{ fontSize: 25 }}>{swishLinks ? (<AiOutlineCheckCircle style={{ color: 'green' }} />) : (<AiOutlineCloseCircle />)}</td>
                    <td style={{ fontSize: 25 }}>{subscriptions ? (<AiOutlineCheckCircle style={{ color: 'green' }} />) : (<AiOutlineCloseCircle />)}</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <div style={{ height: 40 }}></div>
      <BillingCharges portalId={id} />



    </View>
  )
}

export default Customer
