import React, { useState, useEffect } from "react"
import View from "./View"
import { getUser } from "../utils/auth"
import {CustomerService} from '../utils/ApiService';
import { Link } from "gatsby"
import {Table} from 'react-bootstrap'

const Dashboard = () => {
  const user = getUser();
  const [data, setData] = useState([]);
  const { displayName, email, emailVerified } = user;
  const accessToken = user.stsTokenManager.accessToken;

  useEffect(
      () => {
        getData();
      }, []
  )

  function getData() {
    CustomerService.getAll()
      .then(r => setData(r))
      .catch(e => console.log(e));
  }

  return (
    <View title="Dashboard">
        <div>
          <p className="text-sm text-gray-600 flex items-center mb-4">
            Donations today
          </p>
          <p className="text-sm text-gray-600 flex items-center mb-4">
            Customer list:
            
          </p>
          <Table responsive>
            <thead>
              <tr>
                <th>Name</th>
                <th>Portal ID</th>
              </tr>
            </thead>
              <tbody>
                  {data?.map( (customer, key) => (
                      <tr key={key}>
                        <td>
                            <Link to={`/app/customer/${customer.id}`}>
                                {customer.name}
                            </Link>
                        </td>
                        <td>
                                {customer.id}
                        </td>
                      </tr>
                  ))}
                  
              </tbody>
          </Table>
        </div>
    </View>
  )
}

export default Dashboard
