import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/Layout"
import Profile from "../components/Profile"
import Dashboard from "../components/Dashboard"
import Customer from "../components/Customer"
import Login from "../components/Login"
import PrivateRoute from "../components/PrivateRoute"
import Status from "../components/Status"
import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => (
  <Layout>
    <Status />
    <Router>
      <PrivateRoute path="/app/profile" component={Profile} />
      <PrivateRoute path="/app/dashboard" component={Dashboard} />
      <PrivateRoute path="/app/customer/:id" component={Customer} />
      <Login path="/app/login" />
    </Router>
  </Layout>
)

export default App
