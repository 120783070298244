import React, { Component } from 'react';

class Loading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    }
  }

  componentDidMount() {
  }

  callback = (key) => {
    console.log(key);
  };

  render() {

    return(
      <>
      <svg id="loader" width="60px" height="20px" viewBox="0 0 64 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Components" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">

              <circle id="Oval" stroke="#C7A143" strokeWidth="2" cx="0" cy="0" r="6" transform="translate(8 10)">
                <animateTransform attributeName="transform"
                  type="scale"
                  additive="sum"
                  values="0.7 0.7;1 1;0.7 0.7"
                  keyTimes="0;0.5;1"
                  begin="0s"
                  dur="1s"
                  repeatCount="indefinite"
                />
              </circle>

              <circle id="Oval-Copy" stroke="#D14A4F" strokeWidth="2" cx="0" cy="0" r="6" transform="translate(32 10)">
                <animateTransform attributeName="transform"
                  type="scale"
                  additive="sum"
                  values="0.7 0.7;1 1;0.7 0.7"
                  keyTimes="0;0.5;1"
                  begin="0.25s"
                  dur="1s"
                  repeatCount="indefinite"
                />
              </circle>

              <circle id="Oval-Copy-2" stroke="#369EC6" strokeWidth="2" cx="0" cy="0" r="6" transform="translate(56 10)">
                <animateTransform attributeName="transform"
                  type="scale"
                  additive="sum"
                  values="0.7 0.7;1 1;0.7 0.7"
                  keyTimes="0;0.5;1"
                  begin="0.5s"
                  dur="1s"
                  repeatCount="indefinite"
                />
              </circle>

          </g>
      </svg>
      </>
    );

  }
}

export default Loading;
