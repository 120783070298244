import React from "react"
import { navigate } from '@reach/router';
import View from "./View"
import { useState } from "react"
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { setUser, isLoggedIn } from "../utils/auth"
import { useFirebase } from "gatsby-plugin-firebase"
import { Row, Col, Card } from 'react-bootstrap'

const Login = () => {
  const [firebase, setFirebase] = useState();

  useFirebase(firebase => {
    setFirebase(firebase);
  }, [])

  if (isLoggedIn()) {
    navigate(`/app/profile`)
  }

  function getUiConfig(auth) {
    return {
      signInFlow: 'popup',
      signInOptions: [
        auth.GoogleAuthProvider.PROVIDER_ID,
        // auth.EmailAuthProvider.PROVIDER_ID
      ],
      // signInSuccessUrl: '/app/profile',
      callbacks: {
        signInSuccessWithAuthResult: (result) => {
          console.log(result.user);
          const email = result.user.email;
          if(email === 'mark@donorflow.se' || email === 'christian@donorflow.se' || email === 'christoffer@donorflow.se'){
            setUser(result.user);
            navigate('/app/dashboard');
          } else {
            alert('✋ Only authorised users may log in. You have logged in with '+email+'. Please try again with your Donorflow email address.');
            navigate('/');
          }
          
        }
      }
    };
  }

  return (
    <View title="Log In">
      <Row className="justify-content-center">
        <Col md={4}>
          <Card className="shadow-sm pt-5 pb-5">
            <Card.Body>
              <h5 className="text-center pb-3">🔑<br></br>Sign in</h5>
              {firebase && <StyledFirebaseAuth uiConfig={getUiConfig(firebase.auth)} firebaseAuth={firebase.auth()} />}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </View>
  );

}

export default Login
