import React, { useState, useEffect } from "react"
import View from "../View"
import { getUser } from "../../utils/auth"
import { BillingService } from '../../utils/ApiService';
import { Link } from "gatsby"
import { RouteComponentProps } from '@reach/router';
import { Table, Row, Col, Card } from 'react-bootstrap'
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import Loading from '../ui/Loading'

const BillingCharges = (props) => {
    const today = new Date();
    const user = getUser();
    const [lineItems, setLineItems] = useState([]);
    const [totalDonations, setTotalDonations] = useState(0);
    const [totalFees, setTotalFees] = useState(0);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date())
    const [loading, setLoading] = useState(false);

    const { displayName, email, emailVerified } = user;
    const accessToken = user.stsTokenManager.accessToken;

    useEffect(
        () => {
            getData();
        }, []
    )

    function getData() {
        BillingService.getCustomerBillingCharges({
            portal: props.portalId
        })
            .then(r => {
                setTotalDonations(r.total_donations);
                setTotalFees(r.total_fees);
                setLineItems(r.line_items);
            })
            .catch(e => console.log(e));
    }

    function getPeriod() {
        setTotalDonations([]);
        setTotalFees(0);
        setLineItems(0);
        setLoading(true);
        BillingService.getCustomerBillingCharges({
            portal: props.portalId,
            start: moment(startDate).format('YYYY-MM-DD'),
            end: moment(endDate).format('YYYY-MM-DD')
        })
            .then(r => {
                setTotalDonations(r.total_donations);
                setTotalFees(r.total_fees);
                setLineItems(r.line_items);
                setLoading(false);
            })
            .catch(e => console.log(e));
    }

    return (
        <Row>
            <Col>
                <Card>
                    <Card.Body>
                        <h3>Billing Charges</h3>
                        <p>
                            <strong>Total Donations Collected:</strong> {totalDonations}
                        </p>
                        <p>
                            <strong>Total Fees:</strong> {totalFees}
                        </p>
                        <div style={{ height: 30 }}></div>
                        <div className="d-flex">
                            <div style={{ marginRight: 10 }}>
                                <DatePicker
                                    selected={startDate}
                                    onChange={date => setStartDate(date)}
                                    selectsStart
                                    startDate={startDate}
                                    endDate={endDate}
                                    dateFormat="yyyy-MM-dd"
                                />
                            </div>
                            <div style={{ marginRight: 10 }}>
                                <DatePicker
                                    selected={endDate}
                                    onChange={date => setEndDate(date)}
                                    selectsEnd
                                    startDate={startDate}
                                    endDate={endDate}
                                    minDate={startDate}
                                    dateFormat="yyyy-MM-dd"
                                />
                            </div>
                            <button onClick={() => getPeriod()}>Get charges</button>
                        </div>
                        <div style={{ height: 30 }}></div>
                        {loading ? (<Loading />) : (
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Description</th>
                                        <th>Product</th>
                                        <th>Source</th>
                                        <th>Donation Amount Collected</th>
                                        <th>Donorflow Fee</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {lineItems.map((i, key) => {
                                        return (
                                            <tr>
                                                <td>{moment(i.date).format('YYYY-MM-DD')}</td>
                                                <td>{i.description}</td>
                                                <td>{i.product}</td>
                                                <td>{i.source_name}</td>
                                                <td>{i.donation_amount_in_cents / 100}</td>
                                                <td>{i.amount_in_cents / 100}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        )}

                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

export default BillingCharges
