import axios from 'axios'

// const APIDOMAIN = "http://localhost:3000/api/v1"
const APIDOMAIN = "https://api.donorflow.net/v1"

const headers = { headers: { 'X-WOLFRONOD': 'Once upon a time' }};

let customer_actions = {
    getAll: () => {
        return new Promise(
            function(resolve, reject){
                axios.get(`${APIDOMAIN}/42/customers/listcustomers?apikey=123`, headers)
                .then(result => {
                    resolve(result.data);
                })
                .catch(e => {
                    console.log(e);
                    var reason = new Error('Could not fetch data');
                    reject(reason);
                })
            }
        );
    },
    getOne: (id) => {
        return new Promise(
            function(resolve, reject){
                axios.get(`${APIDOMAIN}/42/customers/getcustomer/${id}?apikey=123`, headers)
                .then(result => {
                    resolve(result.data);
                })
                .catch(e => {
                    console.log(e);
                    var reason = new Error('Could not fetch data');
                    reject(reason);
                })
            }
        );
    },
    delete: (id) => {
        return new Promise(
            function(resolve, reject){
                axios.delete(`${APIDOMAIN}/campaigns/${id}`, headers)
                .then(result => {
                    resolve(result.data);
                })
                .catch(e => {
                    console.log(e);
                    var reason = new Error('Could not move contacts');
                    reject(reason + e);
                })
            }
        );
    },
    add: (payload) => {

        return new Promise(
            function(resolve, reject){
                axios.post(`${APIDOMAIN}/contacts`, payload, headers)
                .then(result => {
                    resolve(result.data);
                })
                .catch(e => {
                    console.log(e);
                    var reason = new Error('Could not fetch data');
                    reject(reason);
                })
            }
        );
    },
    update: (id, payload) => {

        return new Promise(
            function(resolve, reject){
                axios.put(`${APIDOMAIN}/contacts/${id}`, payload, headers)
                .then(result => {
                    resolve(result.data);
                })
                .catch(e => {
                    console.log(e);
                    var reason = new Error('Could not fetch data');
                    reject(reason);
                })
            }
        );
    }
}

const billing_actions = {
    getCustomerBillingCharges: ({ portal, start, end }) => {
        console.log(start)
        console.log(end)
        let url = `${APIDOMAIN}/42/billing/charges?apikey=123&portal=${portal}`;
        url += `${start ? '&start='+start : '' }`;
        url += `${end ? '&end='+end : '' }`;
        return new Promise(
            function(resolve, reject){
                axios.get(url, headers)
                .then(result => {
                    resolve(result.data);
                })
                .catch(e => {
                    console.log(e);
                    var reason = new Error('Could not fetch data');
                    reject(reason);
                })
            }
        );
    },
}

export let CustomerService = customer_actions;
export let BillingService = billing_actions;